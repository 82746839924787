<template>
  <svg width="23" height="24" viewBox="0 0 23 24" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_70_4942" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="24">
      <rect width="22.7368" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_70_4942)">
      <path
          d="M8.29654 13.2578H6.79014C6.21323 13.2578 5.82861 13.6745 5.82861 14.2514V15.245V18.4821C5.82861 19.0911 6.21323 19.4757 6.82219 19.5078H8.29654C8.87346 19.5078 9.29012 19.1231 9.29012 18.5142V14.2512C9.25807 13.6743 8.87346 13.2576 8.29654 13.2576V13.2578ZM8.45679 18.5142C8.45679 18.6424 8.42474 18.6744 8.26449 18.7065H6.79014C6.62989 18.7065 6.59784 18.6744 6.59784 18.4821V15.2129V14.2514C6.59784 14.0911 6.66194 14.0591 6.75809 14.0591H8.26449C8.39269 14.0591 8.42474 14.0911 8.42474 14.2193C8.45679 15.9821 8.45679 16.7514 8.45679 18.5142Z"
          />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.822 19.5077C6.21303 19.4757 5.82842 19.0911 5.82842 18.4821V14.2514C5.82842 13.6745 6.21303 13.2578 6.78995 13.2578H8.29634C8.30334 13.2578 8.31031 13.2577 8.31725 13.2578C8.3793 13.2589 8.43907 13.2648 8.49634 13.2753C8.96088 13.3604 9.26174 13.7438 9.28992 14.2512V18.5142C9.28992 19.1231 8.87326 19.5077 8.29634 19.5077H6.822ZM8.09634 13.0576H8.29634C8.63553 13.0576 8.92847 13.1813 9.1405 13.3983C9.35114 13.6138 9.47129 13.9102 9.48962 14.2401L9.49023 14.2512L9.48992 18.5142C9.48992 18.8659 9.36852 19.1681 9.14927 19.3824C8.93072 19.5959 8.62977 19.7077 8.29634 19.7077H6.81147C6.4671 19.6896 6.16774 19.57 5.95381 19.3511C5.73921 19.1315 5.62842 18.8299 5.62842 18.4821V14.2514C5.62842 13.9181 5.74009 13.6181 5.94817 13.4002C6.15713 13.1812 6.45126 13.0578 6.78995 13.0578L8.09634 13.0576ZM8.22524 14.2591H6.79764V18.4821C6.79764 18.4909 6.79772 18.499 6.79787 18.5065H8.24373C8.2483 18.5055 8.25258 18.5045 8.2566 18.5036C8.2566 16.7607 8.25654 15.991 8.22524 14.2591ZM8.26429 18.7065C8.42455 18.6744 8.4566 18.6424 8.4566 18.5142C8.4566 16.7514 8.4566 15.9821 8.42455 14.2193C8.42455 14.0911 8.3925 14.0591 8.26429 14.0591H6.7579C6.66174 14.0591 6.59764 14.0911 6.59764 14.2514V18.4821C6.59764 18.6744 6.62969 18.7065 6.78995 18.7065H8.26429Z"
            />
      <path
          d="M16.5978 11.142H15.0914C14.5145 11.142 14.1299 11.5587 14.1299 12.1356V14.1228V18.4817C14.1299 19.0907 14.5145 19.4753 15.1235 19.5073H16.5978C17.1747 19.5073 17.5914 19.1227 17.5914 18.5138V12.1036C17.5593 11.5266 17.1747 11.142 16.5978 11.142ZM16.7581 18.5138C16.7581 18.642 16.726 18.674 16.5658 18.7061H15.0914C14.9312 18.7061 14.8991 18.674 14.8991 18.4817V14.1228V12.1356C14.8991 11.9754 14.9632 11.9433 15.0594 11.9433H16.5658C16.694 11.9433 16.726 11.9754 16.726 12.1036C16.7581 13.8343 16.7581 16.7511 16.7581 18.5138Z"
          fill="black"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.1128 19.707C14.7684 19.6889 14.469 19.5695 14.2551 19.3506C14.0405 19.131 13.9297 18.8295 13.9297 18.4817V12.1356C13.9297 11.8023 14.0414 11.5024 14.2494 11.2844C14.4584 11.0655 14.7525 10.942 15.0912 10.942H16.5976C16.9321 10.942 17.2246 11.0545 17.4385 11.2634C17.6517 11.4716 17.7725 11.7618 17.7909 12.0925L17.7915 12.1035L17.7912 18.5137C17.7912 18.8655 17.6698 19.1677 17.4505 19.3819C17.232 19.5955 16.931 19.7073 16.5976 19.7073L15.1128 19.707ZM16.5265 12.1433H15.0989V18.4817C15.0989 18.4905 15.099 18.4986 15.0991 18.506H16.545C16.5496 18.5051 16.5539 18.5041 16.5579 18.5031C16.5579 16.7509 16.5578 13.8725 16.5265 12.1433ZM16.5656 18.706C16.7258 18.674 16.7579 18.6419 16.7579 18.5137C16.7579 16.7511 16.7579 13.8343 16.7258 12.1035C16.7258 11.9753 16.6938 11.9433 16.5656 11.9433H15.0592C14.963 11.9433 14.8989 11.9753 14.8989 12.1356V18.4817C14.8989 18.674 14.931 18.706 15.0912 18.706H16.5656ZM15.1233 19.5073C14.5143 19.4753 14.1297 19.0907 14.1297 18.4817V12.1356C14.1297 11.5587 14.5143 11.142 15.0912 11.142H16.5976C17.1745 11.142 17.5591 11.5266 17.5912 12.1035V18.5137C17.5912 19.1227 17.1745 19.5073 16.5976 19.5073H15.1233Z"
            />
      <path
          d="M12.4308 15.2446H10.9244C10.3475 15.2446 9.96289 15.6292 9.96289 16.2381V18.4817C9.96289 19.0907 10.3475 19.4753 10.9565 19.4753H12.4308C13.0077 19.4753 13.4244 19.0907 13.4244 18.4817V16.2061C13.3923 15.6612 13.0077 15.2446 12.4308 15.2446ZM12.5911 18.4817C12.5911 18.6099 12.559 18.674 12.3988 18.674H10.9244C10.7642 18.674 10.7321 18.642 10.7321 18.4817V16.2381C10.7321 16.0779 10.7962 16.0458 10.8924 16.0458H12.3988C12.527 16.0458 12.5911 16.0779 12.5911 16.2381V18.4817Z"
          />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.6245 16.2061H13.6242V18.4817C13.6242 18.8334 13.5028 19.1356 13.2835 19.3499C13.065 19.5635 12.7641 19.6753 12.4306 19.6753H10.9563C10.6091 19.6753 10.3062 19.5648 10.0897 19.3483C9.8732 19.1318 9.7627 18.8289 9.7627 18.4817V16.2381C9.7627 15.891 9.87307 15.5892 10.084 15.3732C10.2954 15.1569 10.5902 15.0446 10.9242 15.0446H12.4306C13.124 15.0446 13.5862 15.5547 13.6239 16.1943L13.6245 16.2061ZM13.4242 16.2061V18.4817C13.4242 19.0907 13.0075 19.4753 12.4306 19.4753H10.9563C10.3473 19.4753 9.9627 19.0907 9.9627 18.4817V16.2381C9.9627 15.6292 10.3473 15.2446 10.9242 15.2446H12.4306C13.0075 15.2446 13.3922 15.6612 13.4242 16.2061ZM12.3909 18.474V16.2458H10.9319V18.474H12.3909ZM12.3986 18.674C12.5588 18.674 12.5909 18.6099 12.5909 18.4817V16.2381C12.5909 16.0779 12.5268 16.0458 12.3986 16.0458H10.8922C10.796 16.0458 10.7319 16.0779 10.7319 16.2381V18.4817C10.7319 18.642 10.764 18.674 10.9242 18.674H12.3986Z"
            />
      <path
          d="M9.80279 4.50766C9.80279 4.2833 9.61048 4.12305 9.38612 4.12305C9.16177 4.12305 9.00151 4.31535 9.00151 4.53971V4.79612C9.03356 5.50124 9.06561 6.23841 8.80921 6.81533C8.5528 7.4243 8.00793 7.93711 7.36691 8.16147C6.72589 8.41788 5.92462 8.38583 5.15539 8.38583H5.02719C4.80283 8.38583 4.64258 8.54608 4.64258 8.77044C4.64258 8.9948 4.80283 9.1871 5.02719 9.1871H5.63616C6.30923 9.1871 7.01435 9.15505 7.65537 8.89864C8.4887 8.57813 9.16177 7.93711 9.54638 7.10379C9.86689 6.36662 9.83484 5.50124 9.80279 4.73202V4.50766Z"
          />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.80132 4.79611V4.5397C8.80132 4.22638 9.03078 3.92303 9.38593 3.92303C9.69924 3.92303 10.0026 4.15249 10.0026 4.50765V4.72784C10.0342 5.48721 10.0709 6.39846 9.7296 7.18352L9.72782 7.18761C9.32243 8.06595 8.61142 8.74475 7.72822 9.08482C7.04899 9.35619 6.31045 9.38709 5.63596 9.38709H5.02699C4.67184 9.38709 4.44238 9.08374 4.44238 8.77043C4.44238 8.43561 4.69218 8.18582 5.02699 8.18582H5.1552C5.23151 8.18582 5.30748 8.18608 5.38298 8.18634C6.08845 8.18881 6.75402 8.19113 7.29244 7.97576L7.30058 7.97251C7.88891 7.76659 8.39048 7.29393 8.62468 6.73771L8.62621 6.73407C8.85965 6.20885 8.83419 5.52385 8.80152 4.80519L8.80132 4.79611ZM9.80259 4.732V4.50765C9.80259 4.28329 9.61029 4.12303 9.38593 4.12303C9.16157 4.12303 9.00132 4.31534 9.00132 4.5397V4.79611C9.03337 5.50123 9.06542 6.2384 8.80901 6.81532C8.5526 7.42429 8.00774 7.9371 7.36672 8.16146C6.78969 8.39227 6.08281 8.38934 5.38635 8.38646C5.30911 8.38614 5.23199 8.38582 5.1552 8.38582H5.02699C4.80264 8.38582 4.64238 8.54607 4.64238 8.77043C4.64238 8.99478 4.80264 9.18709 5.02699 9.18709H5.63596C6.30903 9.18709 7.01416 9.15504 7.65518 8.89863C8.4885 8.57812 9.16157 7.9371 9.54618 7.10378C9.86669 6.3666 9.83464 5.50123 9.80259 4.732Z"
           />
      <path
          d="M15.9567 2.20001H9.77065C9.25783 2.20001 8.80912 2.39232 8.45656 2.74488L3.84122 7.26425C3.42455 7.68091 3.2002 8.22578 3.2002 8.8027V17.9693C3.2002 20.309 5.0912 22.2 7.43093 22.2H15.9565C18.2962 22.2 20.1872 20.309 20.1872 17.9693V6.43093C20.1872 4.0912 18.2962 2.2002 15.9565 2.2002L15.9567 2.20001ZM19.3861 17.9691C19.3861 19.8601 17.8477 21.3986 15.9567 21.3986H7.43111C5.5401 21.3986 4.00165 19.8601 4.00165 17.9691V8.80252C4.00165 8.44995 4.16191 8.06534 4.41832 7.80893L9.03366 3.28956C9.22597 3.09726 9.48237 3.0011 9.77083 3.0011H15.9569C17.8479 3.0011 19.3863 4.53955 19.3863 6.43056L19.3861 17.9691Z"
         />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.9567 2.20018C16.0487 2.20019 16.14 2.20312 16.2306 2.20889C18.442 2.34983 20.187 4.18334 20.187 6.43091V17.9693C20.187 20.309 18.296 22.2 15.9563 22.2H7.43073C5.09101 22.2 3.2 20.309 3.2 17.9693V8.80269C3.2 8.22577 3.42436 7.6809 3.84102 7.26424L8.45636 2.74487C8.80892 2.39231 9.25764 2.2 9.77045 2.2L15.9567 2.20018ZM16.416 2.02365L16.4396 2H9.77045C9.20399 2 8.70497 2.21379 8.3157 2.60268L3.70109 7.12134L3.6996 7.12282C3.24563 7.57678 3 8.17244 3 8.80269V17.9693C3 20.4194 4.98055 22.4 7.43073 22.4H15.9563C18.4065 22.4 20.387 20.4194 20.387 17.9693V6.43091C20.387 4.13597 18.6495 2.25304 16.416 2.02365ZM9.03346 3.28955L4.41812 7.80892C4.16171 8.06533 4.00146 8.44994 4.00146 8.8025V17.9691C4.00146 19.8601 5.53991 21.3985 7.43091 21.3985H15.9565C17.8475 21.3985 19.3859 19.8601 19.3859 17.9691L19.3861 6.43055C19.3861 4.53954 17.8477 3.00109 15.9567 3.00109H9.77064C9.48218 3.00109 9.22577 3.09724 9.03346 3.28955ZM4.55884 7.95105C4.33958 8.17073 4.20146 8.50438 4.20146 8.8025V17.9691C4.20146 19.7496 5.65036 21.1985 7.43091 21.1985H15.9565C17.737 21.1985 19.1859 19.7496 19.1859 17.9691L19.1861 6.43055C19.1861 4.65 17.7372 3.20109 15.9567 3.20109H9.77064C9.52829 3.20109 9.32505 3.28081 9.17488 3.43097L9.1734 3.43246L4.55884 7.95105Z"
            />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconReports",
}
</script>